<template>
    <sale-purchase-module-component/>
</template>

<script>
    import SalePurchaseModuleComponent from "@/components/admin/SalePurchaseModuleComponent";

    export default {
        name: "SalePurchaseModule",
        title: "Compra Venta de Divisas | Private",
        components: { SalePurchaseModuleComponent }
    }
</script>

<style scoped>

</style>